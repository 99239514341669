import { useEffect, useContext } from "react";
import ReactGA from "react-ga";
import GAEventHelper from "../utils/GAEventHelper";
import ReferrerHelper from "../utils/ReferrerHelper";
import { AppContext, ACTION_TYPES } from "../context";

import "typeface-poppins";
import "typeface-red-hat-display";
import "typeface-work-sans";

export const useAppInitialization = () => {
  const [state, dispatch] = useContext(AppContext);

  useEffect(() => {
    const phoneNumber = new ReferrerHelper(document).getPhoneNumber();
    dispatch({ type: ACTION_TYPES.SET_PHONE_NUMBER, payload: phoneNumber });
  }, [state.phoneNumber]);

  useEffect(() => {
    const layoutPathName = window.location.pathname;
    // remove trailing slash
    const layoutPathNameCleaned = layoutPathName.replace(/\/$/, "") || "/";
    const GOOGLE_UA = process.env.GATSBY_GOOGLE_UA;
    const debug = process.env.GATSBY_MY_ENV === "DEV" ? true : false;
    ReactGA.initialize(GOOGLE_UA, { debug });
    ReactGA.pageview(layoutPathNameCleaned);

    window.addEventListener("beforeunload", function () {
      GAEventHelper.triggerQuitEvent({
        options: { window },
      });
    });
  }, []);

  return null;
};
