import React, { useEffect, createContext, useReducer, useContext } from "react";

/**
 * Actions TYPES
 */
export const ACTION_TYPES = {
  SET_PHONE_NUMBER: "SET_PHONE_NUMBER",
};

// Create Context Object
export const AppContext = createContext();

const initState = {
  phoneNumber: {
    default: "",
    internationalFormat: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_PHONE_NUMBER:
      return {
        phoneNumber: action.payload,
      };
    default:
      throw new Error();
  }
};

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initState);

  return (
    <AppContext.Provider value={[state, dispatch]}>
      {props.children}
    </AppContext.Provider>
  );
};
