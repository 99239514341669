import ReactGA from "react-ga";
import MobileDetect from "mobile-detect";
import CONSTANTS from "../utils/constants";
export default class GAEventHelper {
  static triggerClickEvent({
    category = CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.PAGE,
    action = CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.CLICK,
    label,
    options,
  }) {
    return this.triggerEvent({ category, action, label, options });
  }

  static triggerViewEvent({ options }) {
    const category = CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.PAGE;
    const action = CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.VIEW;
    const label = "page";
    return this.triggerEvent({ category, action, label, options });
  }

  static triggerQuitEvent({ options }) {
    const category = CONSTANTS.GOOGLE_ANALYTICS.CATEGORIES.PAGE;
    const action = CONSTANTS.GOOGLE_ANALYTICS.ACTIONS.QUIT;
    const label = "page";
    return this.triggerEvent({ category, action, label, options });
  }

  static triggerEvent({ category, action, label = "", options }) {
    try {
      const path = options.window.location.pathname;
      const version = options.version;
      // Need to link this to section's number.
      // const offset = options.window.pageYOffset;

      // Screen resolution will generate too much data. It will be unexploitable
      // const width =
      //   options.window.devicePixelRatio * options.window.screen.width;
      // const height =
      //   options.window.devicePixelRatio * options.window.screen.height;
      // const screenResolution = `${width}x${height}`;

      let device = null;
      const md = new MobileDetect();
      if (md.phone()) {
        device = "mobile";
      } else if (md.tablet()) {
        device = "tablet";
      } else {
        device = "desktop";
      }

      let fullLabel = `cat(${category})__act(${action})__item(${label})__path(${path})`;
      if (options.offset) {
        fullLabel += `__offset(${options.offset})`;
      }
      fullLabel += `__dc(${device})__v(${version})`;

      // let fullLabel = `${action}__item(${label})__path(${path})__offset(${offset})__dc(${device})__v(${version})`;
      ReactGA.event({
        category: category,
        action: action,
        label: fullLabel,
      });
    } catch (e) {
      console.error("Error when triggering GAevent", e);
    }
  }
}
