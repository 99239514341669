export default {
  API_HOSTNAME: "https://us-central1-selfcity-furet-prod.cloudfunctions.net/",
  API_TOKEN:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ3ZWJzaXRlIiwibmFtZSI6ImRldiIsImlhdCI6MTUxNjIzOTAyMn0.fqZuPnxCjZrg5D26gv7Jdko_NwKdA8vbX9OByHXBUGs",
  REFERRER_STORAGE_KEY: "SELFCITY_REFERRER",
  REFERRER_TYPES: {
    ADS: "ADS",
    DIRECT: "DIRECT",
    ORGANIC: "ORGANIC",
    GMB: "GMB",
    INTERNAL: "INTERNAL",
    SOCIAL: "SOCIAL",
    NA: "NA",
  },
  GOOGLE_ADS_CAMPAIGNS: {
    plombier: "PLOMBIER",
    plombiertls: "PLOMBIER_TLS",
    chaudiere: "CHAUDIERE",
    PLOMBIER: "PLOMBIER",
    PLOMBIER_TLS: "PLOMBIER_TLS",
    CHAUDIERE: "CHAUDIERE",
    CHAUDIERE_GA_POSE: "CHAUDIERE_GA_POSE",
    CHAUDIERE_GA_INTERVENTION: "CHAUDIERE_GA_INTERVENTION",
  },
  ACTIVITY_TYPES: {
    INTERVENTION: "INTERVENTION",
    SUPPLY: "SUPPLY",
  },
  PHONE_NUMBERS: {
    "2920": {
      default: "05 31 60 29 20",
      internationalFormat: "+33531602920",
    },
    "3043": {
      default: "05 31 60 30 43",
      internationalFormat: "+33531603043",
    },
    "4415": {
      default: "05 54 54 44 15",
      internationalFormat: "+33554544415",
    },
    "4426": {
      default: "05 54 54 44 26",
      internationalFormat: "+33554544426",
    },
    "4446": {
      default: "05 54 54 44 46",
      internationalFormat: "+33554544446",
    },
    "4459": {
      default: "05 54 54 44 59",
      internationalFormat: "+33554544459",
    },
    "4462": {
      default: "05 54 54 44 62",
      internationalFormat: "+33554544462",
    },
    "4465": {
      default: "05 54 54 44 65",
      internationalFormat: "+33554544465",
    },
    "4468": {
      default: "05 54 54 44 68",
      internationalFormat: "+33554544468",
    },
    "4471": {
      default: "05 54 54 44 71",
      internationalFormat: "+33554544471",
    },
    "4483": {
      default: "05 54 54 44 83",
      internationalFormat: "+33554544483",
    },
  },
  GOOGLE_ANALYTICS: {
    CATEGORIES: {
      PAGE: "page",
      DEMANDE_DEVIS: "demande-devis",
      GOOGLE_MY_BUSINESS: "google-my-business",
    },
    ACTIONS: {
      OPEN: "open",
      SEND: "send",
      CLICK: "click",
      VIEW: "view",
      QUIT: "quit",
    },
  },
  PAGE_REFERENCES: {
    HOME: "home",
    PLOMBIER_TOULOUSE: "plombier-toulouse",
    PARTENAIRES: "partenaires",
    PLOMBIER_VISIO: "plombier-visio",
    BLOG: "blog",
    PAYMENT: "payment",
  },
  CONTENT_PAGE_REFERENCES: {
    CUMULUS: "cumulus",
    CHAUFFE_EAU: "chauffeEau",
    ENTRETIEN_CHAUDIERE: "entretienChaudiere",
    PROBLEME_CHASSE: "problemeChasse",
    DEBOUCHAGE: "debouchage",
    RECHERCHE_FUITE: "rechercheFuite",
  },
  GOOGLE_REVIEWS_URL: "https://bit.ly/selfcity-reviews",
  GOOGLE_MAPS_API_KEY: "AIzaSyBBHe4s3VAtkUJYfnsC5FD5Vwd35g9NVFk",
  GOOGLE_REVIEWS: {
    averageRating: "4.9",
    totalReviewCount: "110",
  },
};

export const forfaitsList = [
  { price: "85", name: "Entretien de chaudière" },
  { price: "85", name: "Entretien de PAC (Pompe à Chaleur)" },
  { price: "125", name: "Entretien de chauffe-eau thermodynamique" },
  { price: "125", name: "Entretien de climatisation" },
  { price: "60", name: "Dépannage simple avec petite fourniture" },
  { price: "60", name: "Débouchage simple (pompe ou siphon)" },
  { price: "85", name: "Débouchage intermédiaire (produit ou furet)" },
  { price: "85", name: "Changement mécanisme chasse d'eau/vanne/flotteur" },
  { price: "95", name: "Changement de mitigeur lavabo / évier" },
  { price: "115", name: "Changement de mitigeur bain/douche" },
  {
    price: "60",
    name:
      "Remplacement robinet d’arrivée d’eau (lave-vaisselle, lave-linge, WC...)",
  },
  { price: "85", name: "Changement abattant WC" },
  { price: "140", name: "Recherche de fuite" },
  { price: "140", name: "Hydrocurage haute pression" },
  { price: "70", name: "Contrôle VMC" },
  {
    price: "60",
    name:
      "Reprise des joints silicone baignoire ou douche ou lavabo ou évier + 10€ par élément supplémentaire",
  },
  {
    price: "85",
    name: "Remplacement groupe de sécurité",
  },
];
