import URLParse from "url-parse";
import store from "store2";
import CONSTANTS from "./constants";
import NotificationHelper from "./NotificationHelper";
export default class ReferrerHelper {
  constructor(document) {
    this.location = document.location;
    this.referrer = document.referrer;
    this.locationURL = URLParse(this.location, true);
    this.referrerURL = URLParse(this.referrer, true);

    const { GOOGLE_ADS_CAMPAIGNS, REFERRER_TYPES } = CONSTANTS;

    let referrerParams = {
      type: REFERRER_TYPES.NA,
      campaignId: null,
      date: new Date().toISOString(),
      referrer: this.referrer,
      location: this.location.href,
    };

    if (this.isFromGoogleAds()) {
      referrerParams.type = REFERRER_TYPES.ADS;
      const cpgn = this.locationURL.query.cpgn;
      referrerParams.campaignId = GOOGLE_ADS_CAMPAIGNS[cpgn];
      // Track GA for chaudiere campaign
      if (
        referrerParams.campaignId === GOOGLE_ADS_CAMPAIGNS.CHAUDIERE &&
        this.locationURL.query.pose &&
        this.locationURL.query.pose ===
          GOOGLE_ADS_CAMPAIGNS.chaudiere.toLowerCase()
      ) {
        referrerParams.campaignId = GOOGLE_ADS_CAMPAIGNS.CHAUDIERE_GA_POSE;
      } else if (
        referrerParams.campaignId === GOOGLE_ADS_CAMPAIGNS.CHAUDIERE &&
        this.locationURL.query.intervention &&
        this.locationURL.query.intervention ===
          GOOGLE_ADS_CAMPAIGNS.chaudiere.toLowerCase()
      ) {
        referrerParams.campaignId =
          GOOGLE_ADS_CAMPAIGNS.CHAUDIERE_GA_INTERVENTION;
      }
    } else if (this.isGMB()) {
      referrerParams.type = REFERRER_TYPES.GMB;
    } else if (this.isOrganic()) {
      referrerParams.type = REFERRER_TYPES.ORGANIC;
    } else if (this.isSocial()) {
      referrerParams.type = REFERRER_TYPES.SOCIAL;
    } else if (this.isDirect()) {
      referrerParams.type = REFERRER_TYPES.DIRECT;
    } else if (this.isInternal()) {
      referrerParams.type = REFERRER_TYPES.INTERNAL;
    }
    // If referrer not stored yet and not INTERNAL
    // Store REFERRER in session storage
    const currentReferrer = store.session(CONSTANTS.REFERRER_STORAGE_KEY);
    if (!currentReferrer && !this.isInternal()) {
      // Add to session storage
      store.session(CONSTANTS.REFERRER_STORAGE_KEY, referrerParams);

      // Add to local storage
      const localStore = store.get(CONSTANTS.REFERRER_STORAGE_KEY) || [];
      localStore.push(referrerParams);
      store.local(CONSTANTS.REFERRER_STORAGE_KEY, localStore);
      NotificationHelper.sendNotification({ data: "new-referrer" });
    }
  }

  isFromGoogleAds() {
    let res = false;
    const url = this.locationURL;
    if (url && url.query && url.query.cpgn) {
      res = true;
    }
    return res;
  }

  isDirect() {
    let res = false;
    const referrer = this.referrer;
    if (!referrer) {
      res = true;
    }
    return res;
  }

  isGMB() {
    let res = false;
    const url = this.locationURL;
    // ?utm_source=google&utm_medium=gmb
    if (
      url &&
      url.query &&
      url.query.utm_medium &&
      url.query.utm_medium === "gmb"
    ) {
      res = true;
    }
    return res;
  }

  isOrganic() {
    let res = false;
    const referrer = this.referrer;
    if (
      referrer &&
      (referrer.indexOf("google.com") >= 0 ||
        referrer.indexOf("google.fr") >= 0 ||
        referrer.indexOf("bing.com") >= 0 ||
        referrer.indexOf("ecosia.org") >= 0 ||
        referrer.indexOf("baidu.com") >= 0 ||
        referrer.indexOf("duckduckgo.com") >= 0 ||
        referrer.indexOf("qwant.com") >= 0 ||
        referrer.indexOf("qwant.fr") >= 0 ||
        referrer.indexOf("search.yahoo.com") >= 0 ||
        referrer.indexOf("search.lilo.org") >= 0)
    ) {
      res = true;
    }
    return res;
  }

  isSocial() {
    let res = false;
    const referrer = this.referrer;
    if (
      referrer &&
      (referrer.indexOf("twitter.com") >= 0 ||
        referrer.indexOf("//t.co") >= 0 ||
        referrer.indexOf("linkedin.com") >= 0 ||
        referrer.indexOf("lnkd.in") >= 0 ||
        referrer.indexOf("facebook.com") >= 0)
    ) {
      res = true;
    }
    return res;
  }

  isInternal() {
    let res = false;
    const config = process.env;
    if (
      !this.isDirect() &&
      (this.referrerURL.hostname === "localhost" ||
        (config.GATSBY_HOSTNAME &&
          config.GATSBY_HOSTNAME === this.referrerURL.hostname))
    ) {
      res = true;
    }
    return res;
  }

  getPhoneNumber() {
    const {
      GOOGLE_ADS_CAMPAIGNS,
      REFERRER_TYPES,
      PHONE_NUMBERS,
      REFERRER_STORAGE_KEY,
    } = CONSTANTS;

    let res = PHONE_NUMBERS["4465"];

    const referrer = store.session(REFERRER_STORAGE_KEY);
    if (referrer && referrer && referrer.type === REFERRER_TYPES.ADS) {
      switch (referrer.campaignId) {
        case GOOGLE_ADS_CAMPAIGNS.CHAUDIERE:
          res = PHONE_NUMBERS["4459"];
          break;
        case GOOGLE_ADS_CAMPAIGNS.CHAUDIERE_GA_POSE:
          res = PHONE_NUMBERS["4468"];
          break;
        case GOOGLE_ADS_CAMPAIGNS.CHAUDIERE_GA_INTERVENTION:
          res = PHONE_NUMBERS["4415"];
          break;
        case GOOGLE_ADS_CAMPAIGNS.PLOMBIER:
          res = PHONE_NUMBERS["4446"];
          break;
        case GOOGLE_ADS_CAMPAIGNS.PLOMBIER_TLS:
          res = PHONE_NUMBERS["4462"];
          break;

        default:
          break;
      }
    } else if (referrer && referrer.type === REFERRER_TYPES.DIRECT) {
      res = PHONE_NUMBERS["2920"];
    } else if (referrer && referrer.type === REFERRER_TYPES.GMB) {
      res = PHONE_NUMBERS["3043"];
    } else if (referrer && referrer.type === REFERRER_TYPES.ORGANIC) {
      res = PHONE_NUMBERS["4471"];
    } else if (referrer && referrer.type === REFERRER_TYPES.SOCIAL) {
      res = PHONE_NUMBERS["4426"];
    }

    return res;
  }
  /**
   * http://localhost:8000/
   * http://localhost:8000?gclid=11&cpgn=plombier
   * http://localhost:8000?gclid=11&cpgn=chaudiere
   * http://localhost:8000/?gclid=11&cpgn=cumulus
   * http://localhost:8000/?gclid=11&cpgn=wc
   *
   */

  /**
   * {
   *  type: ads,
   *  name: Google,
   *  hostname: google.com,
   *  data: {
   *    campaignId: plombier
   *  }
   * },
   * {
   *  type: direct,
   *  name: ,
   *  hostname: ,
   *  data: {}
   * },
   * {
   *  type: search,
   *  name: Google,
   *  hostname: google.com,
   *  data: {
   *    URL: ...
   *  }
   * },
   * {
   *  type: other,
   *  name: Other,
   *  hostname: other.com,
   *  data: {
   *    URL: ....
   *  }
   * }
   */
}
