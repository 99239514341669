import axios from "axios";
import store from "store2";
import CONSTANTS from "./constants";
export default class NotificationHelper {
  static sendNotification(body = {}) {
    const referrer = store.session(CONSTANTS.REFERRER_STORAGE_KEY);
    const referrerHistory = store.local(CONSTANTS.REFERRER_STORAGE_KEY);

    axios.post(
      "https://us-central1-selfcity-furet-prod.cloudfunctions.net/webhooks",
      {
        source: "selfcity-website",
        body: { referrer, referrerHistory, ...body },
        date: new Date().toISOString()
      }
    );
  }
}
